/** @jsx jsx */
import {jsx} from 'theme-ui'
import PropTypes from 'prop-types'

const Container = ({children, type, p, id}) => (
  <div
    id={id}
    sx={{
      mt: '0',
      mb: [
        (t) => t.layout.separation.mobile,
        '',
        (t) => t.layout.separation.desktop,
      ],
      ml: 'auto',
      mr: 'auto',
      padding: p,
      maxWidth: (t) => t.layout[type],
    }}
  >
    {children}
  </div>
)

export default Container

Container.propTypes = {
  children: PropTypes.node.isRequired,
  p: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.oneOf(['article', 'base', 'big']),
}

Container.defaultProps = {
  type: 'base',
  p: '0 1.25rem',
  id: '',
}
