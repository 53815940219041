/** @jsx jsx */
import {jsx, Button} from 'theme-ui'
import {Link} from 'gatsby'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {linkResolver} from './link-resolver'

const GatsbyBtnLink = styled(Link)``

const GatsbyLink = ({link, children, newTab, ...rest}) => {
  if (link.link_type === `Document`) {
    return (
      <GatsbyBtnLink to={linkResolver(link)} key={link.id} {...rest}>
        <Button
          sx={{
            fontSize: '1.25rem',
          }}
        >
          {children}
        </Button>
      </GatsbyBtnLink>
    )
  }
  return (
    <a href={link.url} target={`${newTab ? `_blank` : null}`}>
      <Button
        sx={{
          fontSize: '1.25rem',
        }}
      >
        {children}
      </Button>
    </a>
  )
}

export {GatsbyLink}

GatsbyLink.defaultProps = {
  newTab: false,
}

GatsbyLink.propTypes = {
  link: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  newTab: PropTypes.bool,
}
