import React from 'react'

function Star() {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2305 0L14.9246 8.2918H23.6431L16.5897 13.4164L19.2839 21.7082L12.2305 16.5836L5.17705 21.7082L7.87121 13.4164L0.817791 8.2918H9.5363L12.2305 0Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="12.2305"
          y1="0"
          x2="12.2305"
          y2="24"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECC94B" />
          <stop offset="1" stopColor="#D69E2E" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Star
