/** @jsx jsx */
import {jsx} from 'theme-ui'
import {Link} from 'gatsby'
import Img from 'gatsby-image'

const WorkerCard = ({
  portrait,
  worker,
  jobTitle,
  workerDesc,
  btn1,
  btn2,
  cardTitle,
  cardDescription,
  requirements,
  requirementDesc,
  preis,
  priceNotes,
}) => (
  <div
    sx={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      margin: '0 2rem',
      boxShadow: '0 9px 16px rgba(0, 0, 0, 0.25)',
      borderRadius: '2rem',
      padding: '2rem 3rem',
      p: {
        mt: 0,
      },
      '@media (max-width: 600px)': {
        margin: '0',
        padding: '2rem 1.5rem',
      },
    }}
  >
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexBasis: 'calc(99.9% * 2 / 5 - 1rem)',
        maxWidth: 'calc(99.9% * 2 / 5 - 1rem)',
        width: 'calc(99.9% * 2 / 5 - 1rem)',
        '@media (max-width: 800px)': {
          flexBasis: '100%',
          maxWidth: '100%',
          width: '100%',
        },
      }}
    >
      <Img
        fluid={portrait}
        alt="Dalia pugatsch portrait"
        sx={{
          width: 'calc(99.9% * 3 / 5 - 1rem)',
          borderRadius: 'full',
          boxShadow: (t) => t.shadows.feature.small.default,
          '@media (max-width: 800px)': {
            textAlign: 'center',
            m: '0 auto 1.5rem auto',
          },
        }}
      />
      <h2
        sx={{
          fontSize: '2rem',
          lineHeight: 1,
          p: '1.5rem 0 0 0',
          m: 0,
        }}
      >
        {worker}
      </h2>
      <h3
        sx={{
          fontSize: '1.5rem',
          m: 0,
          fontWeight: 400,
        }}
      >
        {jobTitle}
      </h3>
      <p
        sx={{
          fontSize: '1.15rem',
          mt: '0.5rem',
        }}
      >
        {workerDesc}
      </p>
      <Link to="/">
        <button
          type="button"
          sx={{
            variant: 'buttons.secondary',
            boxShadow: '0 12px 18px rgba(237, 137, 54, 0.16)',
            '@media (max-width: 600px)': {
              mb: '2.5rem',
              fontSize: '1.4rem',
            },
          }}
        >
          {btn1}
        </button>
      </Link>
    </div>
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexBasis: 'calc(99.9% * 3 / 5 - 1rem)',
        maxWidth: 'calc(99.9% * 3 / 5 - 1rem)',
        width: 'calc(99.9% * 3 / 5 - 1rem)',
        '@media (max-width: 800px)': {
          flexBasis: '100%',
          maxWidth: '100%',
          width: '100%',
        },
        p: {
          fontSize: '1.25rem',
        },
      }}
    >
      <h2
        sx={{
          fontSize: '2rem',
          p: '0 0 0.5rem 0',
          m: 0,
        }}
      >
        {cardTitle}
      </h2>
      <div
        sx={{
          'p:nth-child(1)': {
            mb: 0,
          },
          strong: {
            color: (t) => t.colors.red[6],
          },
        }}
        dangerouslySetInnerHTML={{__html: cardDescription.html}}
      />
      <h2
        sx={{
          fontSize: '1.5rem',
          p: '1.75rem 0 0.5rem 0',
          m: 0,
          lineHeight: 1.2,
        }}
      >
        {requirements}
      </h2>

      <p>{requirementDesc}</p>

      <div
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          '@media (max-width: 600px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
          },
        }}
      >
        <div
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <span
            sx={{
              lineHeight: 0.5,
              pt: '1.5rem',
            }}
          >
            Preis
          </span>
          <strong
            sx={{
              fontSize: '1.5rem',
            }}
          >
            {preis}
          </strong>
          <span sx={{fontSize: '0.8rem'}}>{priceNotes}</span>
        </div>
        <a
          href="mailto:dalia@pugatsch.ch?subject=Anmeldung zum Malkurs"
          type="button"
          sx={{
            variant: 'buttons.primary',
            boxShadow: '0 12px 18px rgba(237, 137, 54, 0.16)',
            fontSize: '1.5rem',
            '&:hover': {
              boxShadow: 'button.hover',
              transform: 'translateY(-8px)',
              color: (t) => t.colors.gray[1],
            },
            '@media (max-width: 600px)': {
              mt: '1rem',
              fontSize: '1.25rem',
            },
          }}
        >
          {btn2}
        </a>
      </div>
    </div>
  </div>
)

export default WorkerCard
