import React from 'react'

export default function SpanishIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="24"
      viewBox="0 0 5 3"
      {...props}
    >
      <rect id="red_stripe" width="5" height="3" y="0" x="0" fill="#c60b1e" />
      <rect
        id="yellow_stripe"
        width="5"
        height="2"
        y="1"
        x="0"
        fill="#ffc400"
      />
      <rect id="red_stripe" width="5" height="1" y="2" x="0" fill="#c60b1e" />
    </svg>
  )
}
