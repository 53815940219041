/** @jsx jsx */
import {useState, useEffect} from 'react'
import {graphql} from 'gatsby'
import {jsx, Flex, Box} from 'theme-ui'
import PropTypes from 'prop-types'
// import { RichText } from 'prismic-reactjs'
import {GatsbyLink} from '../utils/gatsby-link'
import {Close} from '../images/icons'

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== 'undefined'
function ModalWindow({input, handleChange}) {
  return (
    <div
      sx={{
        position: 'fixed',
        top: 0,
        zIndex: 9999,
        height: '100vh',
        width: '100vw',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      }}
    >
      <button
        aria-label="background-close"
        type="button"
        onClick={handleChange}
        sx={{
          height: '100vh',
          width: '100vw',
          background: 'transparent',
          border: 'none',
        }}
      />
      <div
        sx={{
          zIndex: 99999,
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div
          sx={{
            backgroundColor: '#fff',
            padding: ['0.5rem', null, '1rem'],
            maxHeight: '80vh',
            width: ['90vw', null, '80vw'],
            maxWidth: '800px',
            overflowY: 'scroll',
            scrollbarWidth: 'thin',
            boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.6)',
            borderRadius: '1rem',
            textAlign: 'center',
          }}
        >
          <button
            type="button"
            onClick={handleChange}
            sx={{
              padding: ['0.5rem 0.75rem', null, '1rem'],
              position: 'absolute',
              top: 0,
              right: 0,
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            <Close
              sx={{
                height: '32px',
                width: '32px',
                fill: '#000',
                '&:hover': {
                  fill: (t) => t.colors.primary,
                },
              }}
            />
          </button>
          {/* <RichText render={input.primary.message.raw} /> */}
          <div
            sx={{
              paddingTop: '1rem',
              img: {
                py: '2rem',
                maxWidth: '100%',
                margin: '0 auto !important',
              },
              h2: {
                color: (t) => t.colors.primary,
                fontWeight: 'bold',
              },
            }}
            dangerouslySetInnerHTML={{__html: input.primary.message.html}}
          />
          <Flex
            sx={{
              marginBottom: '1rem',
              justifyContent: 'center',
              flexDirection: ['column', null, 'row'],
            }}
          >
            {input.items.map((btn) => (
              <Box mr={2} mb={[3, null, 0]}>
                <GatsbyLink link={btn.promotion_btn_link}>
                  {btn.promotion_btn}
                </GatsbyLink>
              </Box>
            ))}
          </Flex>
        </div>
      </div>
    </div>
  )
}

const PromotionAdvertising = ({input}) => {
  const [isModal, setModal] = useState(() =>
    isBrowser ? window.localStorage.getItem('promotion-window') || true : false
  )
  const [modalTTL, setModalTTL] = useState(() =>
    isBrowser
      ? window.localStorage.getItem('promotion-window-ttl') || Date.now()
      : Date.now()
  )

  useEffect(() => {
    if (isBrowser) {
      window.localStorage.setItem('promotion-window', isModal)
      window.localStorage.setItem('promotion-window-ttl', modalTTL)
    }
  }, [isModal, modalTTL])

  function handleChange() {
    setModal(!isModal)
    setModalTTL(Date.now() + 60000)
  }

  if (isModal === true || Date.now() > modalTTL) {
    return <ModalWindow input={input} handleChange={handleChange} />
  }
  return null
}

export default PromotionAdvertising

export const query = graphql`
  fragment ContactFormInfo on PrismicHomepage {
    data {
      body {
        ... on PrismicHomepageBodyPromotionAdvertising {
          id
          slice_type
          primary {
            message {
              html
            }
          }
          items {
            promotion_btn
            promotion_btn_link {
              uid
              id
              url
              link_type
              type
            }
          }
        }
      }
    }
  }
`

PromotionAdvertising.propTypes = {
  input: PropTypes.object.isRequired,
}

ModalWindow.propTypes = {
  input: PropTypes.object.isRequired,
  handleChange: PropTypes.object.isRequired,
}
