/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
import {jsx, Styled, Flex, Box} from 'theme-ui'
import PropTypes from 'prop-types'
import BackgroundImage from 'gatsby-background-image'

// header

const Hero = ({title, subtitle, bg}) => {
  const overlayBg = [
    bg,
    `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`,
  ].reverse()

  return (
    <BackgroundImage
      fluid={overlayBg}
      sx={{
        position: `relative`,
        minHeight: `90vh`,
        display: `flex`,
        alignItems: `center`,
        mb: [
          (t) => t.layout.separation.mobile,
          '',
          (t) => t.layout.separation.desktop,
        ],
      }}
    >
      <div
        sx={{
          paddingBottom: ['0', '', '', '5rem'],
          maxWidth: ['100vw', '1400px'],
          margin: `0 auto`,
        }}
      >
        <Flex
          sx={{
            alignItems: `center`,
            px: 4,
            flexDirection: [`column`, ``, ``, `row`],
            justifyContent: `space-around`,
            margin: `0 auto`,
          }}
        >
          <Box
            sx={{
              order: [`2`, ``, ``, `1`],
              maxWidth: `1200px`,
              paddingRight: [`0`, ``, ``, `5rem`],
            }}
          >
            <Styled.h1
              sx={{
                textShadow: [(t) => `${t.shadows.text.big}`],
                lineHeight: `1.1`,
                fontSize: ['2rem', '2.5rem', 7],
                mt: 2,
                mb: 3,
                letterSpacing: `wide`,
                color: (t) => t.colors.gray[1],
              }}
            >
              {title}
            </Styled.h1>
            <Styled.h2
              sx={{
                textShadow: [(t) => `${t.shadows.text.big}`],
                fontSize: ['1.5rem', '1.75rem', 6],
                lineHeight: `1.1`,
                fontWeight: `normal`,
                mt: 2,
                mb: 2,
                color: (t) => t.colors.gray[2],
                wordWrap: 'break-word',
              }}
            >
              {subtitle}
            </Styled.h2>
          </Box>
        </Flex>
      </div>
    </BackgroundImage>
  )
}

export default Hero

Hero.defaultProps = {
  title: 'Title',
  subtitle: 'Subtitle',
}

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}
