/** @jsx jsx */
import {useState} from 'react'
import {jsx} from 'theme-ui'
import styled from '@emotion/styled'
import Slider from 'react-slick'
import Lightbox from 'react-image-lightbox'
import {v4 as uuid} from 'uuid'
import {LeftArrow, RightArrow} from '../images/icons'
import 'react-image-lightbox/style.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Wrapper = styled.div`
  position: relative;
  overflow: visible;
  cursor: zoom-in;
  border-radius: 2rem;
  width: calc(99.9% * 1 / 3);
  padding-bottom: 4rem;
  background: #f7fafc;
  margin-bottom: 2rem;
`

const SlickArrow = styled.div`
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  svg {
    height: 3.5rem;
    @media (max-width: 1900px) {
      height: 3rem;
    }
    @media (max-width: 400px) {
      height: 2rem;
    }
  }
`

const Arrows = styled.div`
  z-index: 10;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  padding: 0;
  transform: translate(0, -100%);
  cursor: pointer !important;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;

  @media (max-width: 600px) {
    transform: translate(0, -130%);
  }
`
const CardContent = styled.div`
  display: flex;
  position: absolute;
  flex-basis: calc(99.9% * 1 / 1.1);
  max-width: calc(99.9% * 1 / 1.1);
  width: calc(99.9% * 1 / 1.1);
  top: 0;
  left: 0;
  right: 0;
  bottom: 4rem;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 1.25rem 1.5rem 1.25rem;
  margin: 0 auto;
  z-index: 100;
  border-radius: 2rem;
  &:focus {
    outline: none;
  }
  &:after {
    border-radius: 1rem;
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 40%;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    z-index: -10;
    border-radius: 2rem;
    transition: opacity 0.3s;
  }

  @media (max-width: 600px) {
    height: 25rem;
    margin: 3rem auto 0 auto;
    flex-basis: calc(99.9% * 1 / 1.3333);
    max-width: calc(99.9% * 1 / 1.3333);
    width: calc(99.9% * 1 / 1.3333);
  },
`

const CardTitle = styled.span`
  text-align: left;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 600;
  white-space: normal;
  word-wrap: break-word;
  margin: 0;
  line-height: 1.5;

  @media (max-width: 600px) {
    font-size: 1.25rem;
  }
`

const CardSub = styled.span`
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  white-space: normal;
  word-wrap: break-word;
  line-height: 1;

  @media (max-width: 1440px) {
    font-size: 1.6rem;
  }

  @media (max-width: 600px) {
    font-size: 2rem;
  }
`

function NextArrow(props) {
  const {onClick} = props

  return (
    <Arrows
      sx={{
        right: [0, '', '-3rem'],
      }}
    >
      <SlickArrow onClick={onClick}>
        <RightArrow />
      </SlickArrow>
    </Arrows>
  )
}

function PrevArrow(props) {
  const {style, onClick} = props

  return (
    <Arrows
      sx={{
        left: [0, '', '-3rem'],
      }}
    >
      <SlickArrow
        sx={{
          left: '0.8rem',
        }}
        style={{...style, display: 'block'}}
        onClick={onClick}
      >
        <LeftArrow />
      </SlickArrow>
    </Arrows>
  )
}

function SliderCarousel(props) {
  const {gallery, srcImgs, lb, slidesToShow, captions, capTitle, capDesc} =
    props

  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  const HandleClick = (key) => {
    setIsOpen(true)
    setPhotoIndex(key)
  }

  const images = []
  if (
    srcImgs !== undefined &&
    srcImgs !== null &&
    srcImgs !== '' &&
    lb !== false
  ) {
    srcImgs.forEach((item) => {
      images.push(item.image.url)
    })
  }

  const settings = {
    centerMode: !(gallery.length <= 3),
    autoplay: false,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    infinite: true,
    centerPadding: 0,
    speed: 400,
    slidesToShow,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

  return (
    <div
      sx={{
        // overflowX: 'hidden !important',
        'div.slick-slide.slick-active > div > div:focus': {
          outline: 'none',
        },
        '.slick-slider': {
          // overflowX: 'hidden !important',
        },
        '.slick-slide > div > div': {
          mb: '0 !important',
        },
      }}
    >
      <Slider {...settings} style={{position: 'relative'}}>
        {gallery.map((item, i) => (
          <Wrapper
            key={uuid}
            onClick={() => HandleClick(i)}
            onKeyDown={() => HandleClick(i)}
          >
            {item}
            {captions && (
              <CardContent>
                <CardTitle>{capTitle || 'Masse des Bildes'}</CardTitle>
                <CardSub>{capDesc[i].sec1_desc}</CardSub>
              </CardContent>
            )}
          </Wrapper>
        ))}
      </Slider>
      {!!isOpen && !lb && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
          imageTitle={captions ? capDesc[photoIndex].sec1_desc : ''}
        />
      )}
    </div>
  )
}

export default SliderCarousel
