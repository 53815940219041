import React from 'react'

function PrevArrow() {
  return (
    <svg
      width="106"
      height="72"
      viewBox="0 0 106 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M105 35.9524H5.00001M5.00001 35.9524L35.9278 5M5.00001 35.9524L35.9278 66.9048"
        stroke="#ED8936"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PrevArrow
