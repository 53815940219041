/** @jsx jsx */
import {jsx} from 'theme-ui'

function LightBox({children}) {
  return (
    <div
      sx={{
        zIndex: 999,
        position: 'fixed',
        background: 'rgba(237, 137, 54, 0.9)',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
      }}
    >
      <div
        sx={{
          position: 'relative',
          bordeRadius: '2rem',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: '5rem 5rem',
          minWidth: ' 60vw',
          width: '60vw',
          background: `${(t) => t.colors.gray[1]}`,
          boxShadow: `${(t) => t.shadow.card}`,
          '@media (max-width: 900px)': {
            width: '90vw',
            padding: '3rem',
          },
          '@media (max-width: 600px)': {
            width: '90vw',
            marginTop: 0,
            padding: '1rem',
          },
          '@media (max-width: 320px)': {
            marginTop: '5rem',
            width: '90vw',
            padding: '1rem',
          },
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default LightBox
