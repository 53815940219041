/** @jsx jsx */
import {jsx} from 'theme-ui'
import {Container} from '../elements'
import ContactComponent from './Form'

const tForm = {
  'de-ch': {
    title: 'Interessiert an einem Malkurs?',
    subTitle: 'Kontaktieren Sie mich',
  },
  'es-es': {
    title: 'Interesado en cursos de Arte?',
    subTitle: 'Contacta conmigo',
  },
  'en-gb': {
    title: 'Interested in a painting course?',
    subTitle: 'Contact me',
  },
}

export default function Contact({data, lang = 'de-ch'}) {
  return (
    <div
      sx={{
        mb: [
          (t) => t.layout.separation.mobile,
          '',
          (t) => t.layout.separation.desktop,
        ],
      }}
    >
      <div
        sx={{
          backgroundColor: 'primary',
          p: '3rem 3rem',
        }}
      >
        <div
          sx={{
            maxWidth: '70rem',
            m: '0 auto',
          }}
        >
          <h1
            sx={{
              fontSize: '1rem',
              color: '#fde3ce',
              m: 0,
              lineHeight: 1.1,
              pb: ['0.5rem', '', 0],
            }}
          >
            {tForm[lang].title}
          </h1>
          <h2
            sx={{
              fontSize: '2rem',
              color: (t) => t.colors.gray[1],
              m: 0,
              lineHeight: 1.25,
            }}
          >
            {tForm[lang].subTitle || ''}
          </h2>
          <hr
            sx={{
              backgroundColor: '#fff',
              height: '4px',
              width: ['calc(100vw - 6rem)', '', '12rem'],
              margin: '0.25rem 0 0 0',
              padding: '0',
              border: 0,
            }}
          />
        </div>
      </div>
      <Container>
        <ContactComponent data={data} lang={lang} />
      </Container>
    </div>
  )
}
