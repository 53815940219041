/** @jsx jsx */
import {jsx} from 'theme-ui'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const Image = ({input}) => (
  <div
    sx={{
      pt: '1.5rem',
      pb: '1.5rem',
    }}
  >
    <Img fluid={input.primary.image.localFile.childImageSharp.fluid} />
  </div>
)

export default Image

Image.propTypes = {
  input: PropTypes.object.isRequired,
}
