/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
import PropTypes from 'prop-types'
import {jsx, Styled} from 'theme-ui'
import {Paperplane, Call} from '../images/icons'

function Promotion({title, btn1, btn2}) {
  return (
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
      }}
    >
      <Styled.h1
        sx={{
          lineHeight: 1.25,
        }}
      >
        {title}
      </Styled.h1>
      <div
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          pt: '2rem',
        }}
      >
        <a href="mailto:dalia@pugatsch.ch?subject=Interesse an Kunsterwerb">
          <button
            type="button"
            sx={{
              mr: '2rem',
              mb: ['1.5rem', '', '0'],
              variant: 'buttons.primary',
              fontSize: '1.75rem',
            }}
          >
            <Paperplane /> {btn1}
          </button>
        </a>
        <a href="tel:+41788092042">
          <button
            type="button"
            sx={{
              mr: '2rem',
              mb: ['1.5rem', '', '0'],
              variant: 'buttons.primary',
              fontSize: '1.75rem',
            }}
          >
            <Call />

            {btn2}
          </button>
        </a>
      </div>
    </div>
  )
}

Promotion.defaultProps = {
  title: 'Text content',
  btn1: 'Button action',
  btn2: 'Button action',
}

Promotion.propTypes = {
  title: PropTypes.string,
  btn1: PropTypes.string,
  btn2: PropTypes.string,
}

export default Promotion
