/** @jsx jsx */
import {jsx} from 'theme-ui'
import PropTypes from 'prop-types'

const BodyText = ({input}) => (
  <div
    sx={{
      maxWidth: (t) => t.layout.article,
    }}
    dangerouslySetInnerHTML={{__html: input.primary.text.html}}
  />
)

export default BodyText

BodyText.propTypes = {
  input: PropTypes.object.isRequired,
}
