/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
// import PropTypes from 'prop-types';
import Img from 'gatsby-image'
import {jsx, Styled} from 'theme-ui'
import {Link} from 'gatsby'
import GatsbyLink from '../utils/gatsbyLink'

const ContentImg = ({
  title,
  content,
  img,
  btn1,
  btn2,
  btn1Link,
  primaryAnchor,
  btn2Link,
  secondaryAnchor,
}) => (
  <div
    sx={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginBottom: '4rem',
      maxWidth: '1200px',
      margin: '0 auto',
    }}
  >
    <div
      sx={{
        flexBasis: 'calc(99.9% * 3 / 5 - 1rem)',
        maxWidth: 'calc(99.9% * 3 / 5 - 1rem)',
        width: 'calc(99.9% * 3 / 5 - 1rem)',
        '@media (max-width: 800px)': {
          flexBasis: '100%',
          maxWidth: '100%',
          width: '100%',
        },
      }}
    >
      <Styled.h1>{title}</Styled.h1>
      <Styled.p>{content}</Styled.p>
      <div
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <GatsbyLink link={btn1Link} anchor={primaryAnchor}>
          <button
            type="button"
            sx={{
              mr: '2rem',
              mb: ['1.5rem', '', '0'],
              variant: 'buttons.primary',
            }}
          >
            {btn1}
          </button>
        </GatsbyLink>
        {btn2 && (
          <GatsbyLink link={btn2Link} anchor={secondaryAnchor}>
            <button
              type="button"
              sx={{
                variant: 'buttons.secondary',
              }}
            >
              {btn2}
            </button>
          </GatsbyLink>
        )}
      </div>
    </div>

    {/* Image */}
    <div
      sx={{
        position: 'relative',
        mt: ['3rem', '', '', '0'],
        transition: (t) => t.transitions.boom.transition,
        borderRadius: (t) => t.borderRadius.default,

        div: {
          borderRadius: (t) => t.borderRadius.default,
          boxShadow: (t) => t.shadows.feature.small.default,
          '&:hover': {
            boxShadow: `${(t) => t.shadow.feature.small.hover}`,
          },
        },
        height: '100%',
        img: {
          borderRadius: (t) => t.borderRadius.default,
          margin: '0 auto',
          width: '100%',
        },
        '&:hover': {
          transform: 'translateY(-12px)',
        },
        a: {
          borderRadius: `${(t) => t.borderRadius.default}`,
          // '& > div': {
          //   position: 'static !important',
          // },
          img: {
            height: '100%',
            width: '100%',
            margin: '0 auto',
          },
          // '& > div > div': {
          //   position: 'static !important',
          // },
          '&:focus': {
            outline: 'none',
            boxShadow: `0 0 0 5px ${(t) => t.colors.primary}`,
          },
        },
        flexBasis: 'calc(99.9% * 2 / 5 - 1rem)',
        maxWidth: 'calc(99.9% * 2 / 5 - 1rem)',
        width: 'calc(99.9% * 2 / 5 - 1rem)',
        '@media (max-width: 800px)': {
          flexBasis: '100%',
          maxWidth: '100%',
          width: '100%',
        },
      }}
    >
      <Link to={btn1Link.uid}>
        <Img fluid={img} />
      </Link>
    </div>
  </div>
)

export default ContentImg
