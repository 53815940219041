/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
import React, {useState} from 'react'
import axios from 'axios'
import {graphql} from 'gatsby'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import {jsx, Styled, Input, Textarea} from 'theme-ui'
import {Container, LightBox} from '../elements'
import {SliceZone} from '.'

const contactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Der Name ist zu kurz')
    .max(50, 'Der Name ist zu lang!')
    .required('Erforderlich'),
  message: Yup.string().required('Erforderlich'),
  email: Yup.string().email('Ungültige Emailadresse').required('Erforderlich'),
})

export default function ContactComponent(data) {
  const {title, body, button} = data
  const [isLight, setLight] = useState(false)
  const [, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: {ok, msg},
    })
    if (ok) {
      form.reset()
    }
  }
  const handleOnSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    setServerState({submitting: true})
    axios({
      method: 'post',
      url: 'https://getform.io/f/a0b274cd-1fb5-453d-aa26-4f0e13cdfd26',
      data: new FormData(form),
    })
      .then(() => {
        handleServerResponse(true, setLight(true), form)
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }

  return (
    <React.Fragment>
      <Container type="base">
        <Formik
          initialValues={{
            name: '',
            email: '',
            message: '',
          }}
          validationSchema={contactSchema}
          onSubmit={handleOnSubmit}
        >
          {({touched, errors, isSubmitting, handleReset}) => (
            <Form
              name="dalia-contact"
              onSubmit={handleOnSubmit}
              onReset={handleReset}
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              sx={{mt: `3rem`}}
            >
              <label
                htmlFor="name"
                sx={{
                  fontSize: `1.5rem`,
                  fontWeight: 'bold',
                }}
              >
                {data.name}
              </label>
              <Field
                name="name"
                as={Input}
                sx={{
                  my: `1rem`,
                  borderColor: 'transparent',
                  borderRadius: '4px',
                  color: (t) => `${t.colors.gray[9]}`,
                  background: `${
                    errors.name && touched.name ? 'rgba(229, 62, 62, 0.2)' : ``
                  }`,
                  boxShadow: '2px 4px 16px rgba(0, 0, 0, 0.12)',

                  '&:focus': {
                    outline: (t) =>
                      `${
                        errors.mail
                          ? `${t.colors.red[4]} 5px`
                          : `${t.colors.primary}`
                      }`,
                    outlineColor: (t) =>
                      `${
                        errors.name
                          ? `${t.colors.red[4]}`
                          : `${t.colors.primary}`
                      }`,
                    outlineStyle: `auto`,
                    outlineWidth: `5px`,
                  },
                }}
              />
              {errors.name && touched.name ? (
                <span
                  sx={{
                    display: `inline-block !important`,
                    width: `100vw !important`,
                    pt: `0`,
                    pb: `1rem`,
                    color: (t) => t.colors.red[6],
                    fontWeight: `500`,
                  }}
                >
                  {errors.name}
                </span>
              ) : null}

              <label
                htmlFor="email"
                sx={{
                  fontSize: `1.5rem`,
                  fontWeight: 'bold',
                }}
              >
                {data.mail}
              </label>
              <Field
                name="email"
                as={Input}
                sx={{
                  my: `1rem`,
                  borderColor: 'transparent',
                  borderRadius: '4px',
                  color: (t) => `${t.colors.gray[9]}`,
                  background: `${
                    errors.email && touched.email
                      ? 'rgba(229, 62, 62, 0.2)'
                      : ``
                  }`,
                  boxShadow: '2px 4px 16px rgba(0, 0, 0, 0.12)',
                  '&:focus': {
                    outline: (t) =>
                      `${
                        errors.mail
                          ? `${t.colors.red[4]} 5px`
                          : `${t.colors.primary}`
                      }`,
                    outlineColor: (t) =>
                      `${
                        errors.email
                          ? `${t.colors.red[4]}`
                          : `${t.colors.primary}`
                      }`,
                    outlineStyle: `auto`,
                    outlineWidth: `5px`,
                  },
                }}
              />

              {errors.email && touched.email ? (
                <span
                  sx={{
                    display: `inline-block !important`,
                    width: `100vw !important`,
                    pt: `0`,
                    pb: `1rem`,
                    color: (t) => t.colors.red[6],
                    fontWeight: `500`,
                  }}
                >
                  {errors.email}
                </span>
              ) : null}

              <label
                htmlFor="message"
                sx={{
                  fontSize: `1.5rem`,
                  fontWeight: 'bold',
                }}
              >
                {data.nachricht}
              </label>
              <Field
                name="message"
                as={Textarea}
                rows={8}
                sx={{
                  my: `1rem`,
                  borderColor: 'transparent',
                  borderRadius: '4px',
                  color: (t) => `${t.colors.gray[9]}`,
                  background: `${
                    errors.message && touched.message
                      ? 'rgba(229, 62, 62, 0.2)'
                      : ``
                  }`,
                  boxShadow: '2px 4px 16px rgba(0, 0, 0, 0.12)',

                  '&:focus': {
                    outline: (t) =>
                      `${
                        errors.message
                          ? `${t.colors.red[4]} 5px`
                          : `${t.colors.primary}`
                      }`,
                    outlineColor: (t) =>
                      `${
                        errors.mail
                          ? `${t.colors.red[4]}`
                          : `${t.colors.primary}`
                      }`,
                    outlineStyle: `auto`,
                    outlineWidth: `5px`,
                  },
                }}
              />
              {errors.message && touched.message ? (
                <span
                  sx={{
                    display: `inline-block !important`,
                    width: `100vw !important`,
                    pt: `0`,
                    pb: `1rem`,
                    color: (t) => t.colors.red[6],
                    fontWeight: `500`,
                  }}
                >
                  {errors.message}
                </span>
              ) : null}

              <div
                sx={{
                  m: '0 auto',
                  textAlign: 'center',
                }}
              >
                <button
                  type="submit"
                  disabled={isSubmitting}
                  sx={{
                    mt: '1.5rem',
                    variant: 'buttons.primary',
                    px: '3rem',
                    fontSize: ['1.4rem', '', '1.75rem'],
                  }}
                >
                  Senden
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
      {isLight && (
        <LightBox>
          <div
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: {
                color: (t) => t.colors.primaryMuted,
                fontSize: ['1rem', '', '', '1.75rem'],
                textAlign: 'center',
                maxWidth: '900px',
              },
            }}
          >
            <Styled.h1
              sx={{
                color: (t) => t.colors.gray[1],
                textAlign: 'center',
                mb: '-6rem',
              }}
            >
              {title}
            </Styled.h1>
            <SliceZone allSlices={body} />
            <div
              sx={{
                textAlign: 'center',
                mt: 2,
              }}
            >
              <button
                type="button"
                sx={{
                  variant: 'buttons.secondary',
                  backgroundColor: '#fff',
                  border: (t) => `2px solid ${t.colors.gray}`,
                }}
                onClick={() => setLight(false)}
              >
                {button}
              </button>
            </div>
          </div>
        </LightBox>
      )}
    </React.Fragment>
  )
}

export const query = graphql`
  fragment FormInformation on PrismicForm {
    data {
      title
      button
      name
      mail
      nachricht
      body {
        ... on PrismicFormBodyText {
          slice_type
          id
          primary {
            text {
              html
            }
          }
        }

        ... on PrismicFormBodyBilder {
          slice_type
          id
          primary {
            image {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
