/** @jsx jsx */
import React from 'react'
import {StaticQuery, graphql, Link} from 'gatsby'
import {Global, css} from '@emotion/core'
import {jsx} from 'theme-ui'
import CookieConsent from 'react-cookie-consent'
import Footer from './footer'

const PureLayout = ({children, data, className}) => (
  <React.Fragment>
    <Global
      styles={(theme) => ({
        '*': {
          boxSizing: `inherit`,
          '&:before': {
            boxSizing: `inherit`,
          },
          '&:after': {
            boxSizing: `inherit`,
          },
        },
        html: {
          fontSize: `16px`,
        },
        p: {
          fontSize: '1.3125rem',
        },
        'h1, h2, h3, h4': {
          lineHeight: 1.25,
        },
        '::selection': {
          backgroundColor: theme.colors.primary,
          color: theme.colors.background,
        },
        body: {
          overflowX: 'hidden',
        },
      })}
    />
    <Global
      styles={css`
        .slick-dots li {
          border-radius: 1rem !important;
          margin-right: 2rem !important;
          height: 90px;
          width: 90px;
          box-shadow: 8px 24px 30px rgba(0, 0, 0, 0.12);
        }

        .slick-dots li img {
          border-radius: 1rem !important;
          height: 90px;
          width: 90px;
        }

        .slick-dots {
          margin-top: 10rem !important;
          bottom: -8rem !important;
        }

        .slick-track {
          overflow: hidden !important;
        }
        @media (max-width: 800px) {
          .slick-dots li {
            display: none !important;
          }
        }
      `}
    />
    <CookieConsent
      acceptOnScroll={false}
      style={{
        fontSize: '1rem',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        padding: '1rem 0.3rem',
        lineHeight: '1.4',
        background:
          'linear-gradient(45deg, rgba(237, 137, 54, 0.9), rgba(241, 161, 95, 0.9))',
      }}
      location="bottom"
      buttonStyle={{
        fontWeight: 'bold',
        color: '#ED8936',
        fontSize: '1.1rem',
        backgroundColor: '#fff',
        borderRadius: '900px',
        padding: '1rem 1.5rem',
        margin: 'auto 1rem auto 1rem',
      }}
      buttonText="EINVERSTANDEN"
      declineButtonText="Decline"
      cookieName="gatsby-gdpr-google-analytics"
    >
      Um Ihnen den bestmöglichen Service zu gewährleisten, verwenden wir Cookies
      auf dieser Website. Aufgrund der europäischen Datenschutzverordnung
      benötigen wir dazu Ihre Zustimmung. Weitere Informationen zum
      Datengebrauch finden Sie in unseren{' '}
      <Link
        style={{
          color: '#fff',
          fontWeight: '600',
          textDecoration: 'underline',
        }}
        to="/datenschutzbestimmungen"
      >
        Datenschutzbestimmungen
      </Link>
      .
    </CookieConsent>
    <main className={className}>{children}</main>
    <Footer data={data.prismicFooter.data} />
  </React.Fragment>
)

function Layout(props, {lang}) {
  return (
    <StaticQuery
      query={graphql`
        query Layout {
          prismicFooter {
            data {
              links {
                link_name
                link {
                  uid
                }
              }
              copyrightzeichen
              facebook_link {
                url
              }
              instagram_link {
                url
              }
              titel
              c1_title
              btn_name
              logo {
                localFile {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <PureLayout {...props} data={data} lang={lang}>
          {props.children}
        </PureLayout>
      )}
    />
  )
}
export default Layout
