/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
// import PropTypes from 'prop-types';
import PropTypes from 'prop-types'
import {jsx, Styled} from 'theme-ui'
import SliderCarousel from './SliderCarousel'
import GalleryImgs from '../utils/galleryImgs'

const ContentCarousel = ({title, content, imgs, captions, capDesc}) => (
  // Wrapper
  <div
    sx={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginBottom: '4rem',
      maxWidth: '1200px',
      margin: '0 auto',
    }}
  >
    <div
      sx={{
        flexBasis: 'calc(99.9% * 3 / 5 - 1rem)',
        maxWidth: 'calc(99.9% * 3 / 5 - 1rem)',
        width: 'calc(99.9% * 3 / 5 - 1rem)',
        '@media (max-width: 1200px)': {
          flexBasis: '100%',
          maxWidth: '100%',
          width: '100%',
        },
      }}
    >
      <Styled.h1>{title}</Styled.h1>
      <Styled.p>{content}</Styled.p>
    </div>

    {/* Image */}
    <div
      sx={{
        position: 'relative',
        mt: ['3rem', '', '2rem', '', 0],
        pr: '2rem',
        transition: (t) => t.transitions.boom.transition,
        borderRadius: (t) => t.borderRadius.default,
        div: {
          mt: '0 !important',
        },
        img: {
          cursor: 'zoom-in',
        },
        flexBasis: 'calc(99.9% * 2 / 5 - 5rem)',
        maxWidth: 'calc(99.9% * 2 / 5 - 5rem)',
        width: 'calc(99.9% * 2 / 5 - 5rem)',
        '@media (max-width: 1200px)': {
          flexBasis: '100%',
          maxWidth: '100%',
          width: '100%',
          pr: '2rem',
          pl: '2rem',
        },
        '@media (max-width: 500px)': {
          maxWidth: '100%',
          width: '100%',
          pr: '0',
          pl: '0',
        },
      }}
    >
      <SliderCarousel
        gallery={GalleryImgs(imgs, true)}
        srcImgs={imgs}
        slidesToShow={1}
        captions
        capDesc={capDesc}
      />
    </div>
  </div>
)

ContentCarousel.defaultProps = {
  title: 'Title',
  content: 'Content',
  captions: false,
}

ContentCarousel.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  captions: PropTypes.bool,
}

export default ContentCarousel
