/** @jsx jsx */
import {useState} from 'react'
import {jsx} from 'theme-ui'
import Slider from 'react-slick'
import Lightbox from 'react-image-lightbox'
import Img from 'gatsby-image'
import {v4 as uuidv4} from 'uuid'

function BuchCarousel(props) {
  const {gallery, lb} = props

  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  const HandleClick = (key) => {
    setIsOpen(true)
    setPhotoIndex(key)
  }

  const images = []

  gallery.forEach((item) => {
    images.push(item.image.url)
  })

  const settings = {
    customPaging(i) {
      return (
        <a>
          <Img fluid={gallery[i].image.localFile.childImageSharp.fluid} />
        </a>
      )
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <div
      sx={{
        position: 'relative',
        overflow: 'visible',
        mb: ['6rem', '6rem', '6rem', '0'],
        'div.slick-slide.slick-active > div > div:focus': {
          outline: 'none',
        },
        '.slick-dots li': {
          borderRadius: '1rem !important',
          marginRight: '2rem !important',
          height: '4rem',
          width: '4rem',
          boxShadow: '8px 24px 30px rgba(0, 0, 0, 0.12)',
        },

        '.slick-dots li img': {
          borderRadius: '1rem !important',
          height: '4rem',
          width: '4rem',
        },

        '.slick-dots': {
          marginTop: '10rem !important',
          bottom: '-6rem !important',
        },
      }}
    >
      <Slider {...settings}>
        {gallery.map((item, i) => (
          <div
            sx={{
              overflow: 'visible',
              position: 'relative',
              cursor: 'pointer',
              div: {
                borderRadius: '0 1rem 1rem 0',
                height: '100%',
              },
              img: {
                height: '100%',
              },
            }}
            key={uuidv4}
            role="slider"
            onClick={() => HandleClick(i)}
            onKeyDown={() => HandleClick(i)}
            tabIndex={0}
          >
            <Img
              fluid={item.image.localFile.childImageSharp.fluid}
              alt="Kunst revolution Buch"
            />
          </div>
        ))}
      </Slider>
      {!!isOpen && !lb && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  )
}

export default BuchCarousel
