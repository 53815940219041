exports.linkResolver = (doc) => {
  if (doc.type === `homepage`) {
    return `/`
  }
  if (doc.type === `blogeintrag`) {
    return `/blog/${doc.uid}`
  }
  if (doc.type === `events`) {
    return `/veranstaltungen/${doc.uid}`
  }

  // Backup for all other types
  return `/`
}
