/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
/** @jsx jsx */
import {useRef} from 'react'
import PropTypes from 'prop-types'
import {jsx} from 'theme-ui'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import Slider from 'react-slick'
import {
  NextArrow as NextArrowIcon,
  PrevArrow as PrevArrowIcon,
} from '../images/icons'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const SlickArrow = styled.div`
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  svg {
    height: 3.5rem;
    @media (max-width: 1900px) {
      height: 3rem;
    }
    @media (max-width: 400px) {
      height: 2rem;
    }
  }
`

const Arrows = styled.div`
  z-index: 10;
  font-size: 0;
  line-height: 0;
  display: block;
  padding: 0;
  cursor: pointer !important;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  @media (max-width: 600px) {
  }
`

function NextArrow(props) {
  const {onClick} = props

  return (
    <Arrows sx={{mb: '2rem'}}>
      <SlickArrow onClick={onClick}>
        <NextArrowIcon />
      </SlickArrow>
    </Arrows>
  )
}

function PrevArrow(props) {
  const {onClick} = props

  return (
    <Arrows sx={{}}>
      <SlickArrow sx={{}} onClick={onClick}>
        <PrevArrowIcon />
      </SlickArrow>
    </Arrows>
  )
}

function TestimonialsCarousel(props) {
  const sliderRef = useRef(null)
  const {testimonials} = props

  const settings = {
    centerMode: true,
    autoplay: false,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    infinite: true,
    centerPadding: 0,
    speed: 400,
    slidesToShow: 1,
    arrows: true,
    adaptiveHeight: true,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
  }

  return (
    <div
      sx={{
        overflow: 'visible',
        'div.slick-slide.slick-active > div > div:focus': {
          outline: 'none',
        },
        // '.slick-track': {
        //   display: 'flex !important',
        // },
        // '.slick-slide': {
        //   height: 'inherit !important',
        // },
        // '.slick-slide > div': { height: '100%' },
      }}
    >
      <Slider {...settings} ref={sliderRef}>
        {testimonials.map((item) => (
          <div
            sx={{
              display: 'flex !important',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '4rem',
              maxWidth: '1200px',
              margin: '0 auto',
              outline: 0,
            }}
          >
            {/* Image */}
            <div
              sx={{
                display: 'flex !important',
                flexDirection: 'column',
                overflow: 'visible',
                position: 'relative',
                borderRadius: '999px',
                p: {
                  m: '0 !important',
                  color: '#4A5568',
                },
                div: {
                  borderRadius: '999px',
                  boxShadow: (t) => t.shadows.feature.small.default,
                },
                flexBasis: 'calc(99.9% * 2 / 8 - 1rem)',
                maxWidth: 'calc(99.9% * 2 / 8 - 1rem)',
                width: 'calc(99.9% * 2 / 8 - 1rem)',
                '@media (max-width: 800px)': {
                  flexBasis: '100%',
                  maxWidth: '100%',
                  width: '100%',
                },
              }}
            >
              <Img
                fluid={
                  item.node.data.portrait_photo.localFile.childImageSharp.fluid
                }
                sx={{
                  borderRadius: '999px',
                  margin: ['0 auto', '', '0 auto 0 0'],
                  width: '15rem',
                  height: '15rem',
                }}
              />
              <h3
                sx={{
                  mb: '0.5rem',
                  lineHeight: 1,
                  fontSize: '2rem',
                  color: '#4A5568',
                }}
              >
                {item.node.data.name}
              </h3>

              <span
                dangerouslySetInnerHTML={{
                  __html: item.node.data.job_title.html,
                }}
              />
            </div>
            <div
              sx={{
                flexBasis: 'calc(99.9% * 3 / 4 - 1rem)',
                maxWidth: 'calc(99.9% * 3 / 4 - 1rem)',
                width: 'calc(99.9% * 3 / 4 - 1rem)',
                '@media (max-width: 800px)': {
                  flexBasis: '100%',
                  maxWidth: '100%',
                  width: '100%',
                },
              }}
            >
              <div>
                <p
                  sx={{
                    pl: [0, 0, '1.5rem'],
                    pb: '1rem',
                    fontSize: [2, 3, '2.5rem'],
                    color: '#2D3748',
                    fontWeight: 'bold',
                    lineHeight: 1.1,
                    textShadow: '8px 24px 30px rgba(0, 0, 0, 0.12)',
                  }}
                >
                  {item.node.data.msg}
                </p>
              </div>
              <div
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <NextArrow
                  type="prev"
                  onClick={() => sliderRef.current.slickNext()}
                />
                <PrevArrow
                  type="prev"
                  onClick={() => sliderRef.current.slickPrev()}
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

TestimonialsCarousel.defaultProps = {
  slidesToShow: 1,
}

TestimonialsCarousel.propTypes = {
  portrait: PropTypes.arrayOf(PropTypes.object),
  slidesToShow: PropTypes.number,
}

export default TestimonialsCarousel
