import React from 'react'

const LeftArrow = ({color, className}) => (
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 257.57 451.85"
    className={className}
  >
    <path
      d="M106.41,248.29,300.69,442.57a31.64,31.64,0,0,0,44.75-44.74L173.53,225.92,345.44,54A31.64,31.64,0,0,0,300.69,9.27L106.4,203.55a31.64,31.64,0,0,0,0,44.74Z"
      transform="translate(-97.14 0)"
      fill={color || '#ED8936'}
    />
  </svg>
)

export default LeftArrow
