import React from 'react'

function NextArrow() {
  return (
    <svg
      width="110"
      height="72"
      viewBox="0 0 110 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 35.9524H105M105 35.9524L74.0722 5M105 35.9524L74.0722 66.9048"
        stroke="#ED8936"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default NextArrow
