/** @jsx jsx */
import {jsx} from 'theme-ui'

const Facebook = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    sx={{
      '&:hover': {
        path: {
          fill: '#1877F2',
        },
      },
    }}
  >
    <defs>
      <linearGradient id="fbGradient" gradientTransform="rotate(40)">
        {/* radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949
        45%,#d6249f 60%,#285AEB 90%) */}
        <stop offset="0%" stopColor="#4267b2" />
        <stop offset="16.666%" stopColor="#4978c4" />
        <stop offset="49.998%" stopColor="#518ad8" />
        <stop offset="66.664%" stopColor="#5798e5" />
        <stop offset="83.33" stopColor="#66a6ea" />
        <stop offset="100%" stopColor="#7cb6ed" />
      </linearGradient>
    </defs>
    <path d="M22.675 0H1.325C.593 0 0 .593 0 1.325v21.351C0 23.407.593 24 1.325 24H12.82v-9.294H9.692v-3.622h3.128V8.413c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12V24h6.116c.73 0 1.323-.593 1.323-1.325V1.325C24 .593 23.407 0 22.675 0z" />
  </svg>
)

export default Facebook
