/** @jsx jsx */
import React from 'react'
import {jsx} from 'theme-ui'
import {Link} from 'gatsby'
import SpanishIcon from './icons/spanish'
import GermanIcon from './icons/german'
import EnglishIcon from './icons/english'
import DownArrow from '../../images/icons/ArrowDown'
import locales from '../../../config/i18n'

const {langPrefix} = require('../../utils/lang-prefix')

const langList = ['de-ch', 'es-es', 'en-gb']
const languagesVerbose = {
  'de-ch': 'Deutsch',
  'es-es': 'Español',
  'en-gb': 'English',
}

function currentFlagLang(lang) {
  switch (lang) {
    case 'es-es':
      return <SpanishIcon />
    case 'en-gb':
      return <EnglishIcon />
    case 'de-ch':
      return (
        <GermanIcon
          sx={{
            height: ['39px', null, '24px'],
          }}
        />
      )
    default:
      return <GermanIcon />
  }
}

const SwitchButton = ({lang, isExpanded, i18nPathname}) => (
  <React.Fragment>
    <div
      sx={{
        my: 'auto',
        position: 'relative',
        '&:hover #switcher-collapse': {
          display: 'flex',
          flexDirection: 'column',
        },
        '#switcher-collapse a:nth-child(n+2)': {
          paddingTop: '0.5rem',
        },
        svg: {
          verticalAlign: 'middle',
        },
      }}
    >
      {/* Display current flag */}
      {currentFlagLang(lang)}
      <DownArrow height="15px" width="15px" />
      <div
        id="switcher-collapse"
        sx={{
          boxShadow: (t) => t.shadows.card,
          p: isExpanded ? '1rem 1.25rem' : '1.5rem 1rem',
          width: isExpanded ? '100%' : '200px',
          display: 'none',
          position: isExpanded ? 'inline-block' : 'absolute',
          left: '0',
          backgroundColor: (t) => t.colors.gray[2],
        }}
      >
        <p
          sx={{
            fontSize: '0.9rem',
            fontWeight: 'bold',
            color: (t) => t.colors.gray[9],
            mt: 0,
            mb: 2,
          }}
        >
          {locales[lang].navbar.langSwitcher.title}
        </p>
        {i18nPathname === undefined &&
          langList
            .filter((item) => item !== lang)
            .map((langItem) => <FlagLink uid="" lang={langItem} />)}
        {i18nPathname !== undefined &&
          Object.values(i18nPathname)
            .filter((item) => item.lang !== lang)
            .map((nodes) => <FlagLink uid={nodes.uid} lang={nodes.lang} />)}
      </div>
    </div>
  </React.Fragment>
)

function FlagLink({uid, lang}) {
  return (
    <Link
      to={langPrefix(uid, lang)}
      sx={{
        cursor: 'pointer',
        '-webkit-transform': 'scale3d(1,1,1)',
        transform: 'scale3d(1,1,1)',
        '-webkit-transition': 'all .2s',
        transition: 'all .2s',
        '&:hover': {
          '-webkit-transform': 'scale3d(1.05,1.05,1.05)',
          transform: 'scale3d(1.05,1.05,1.05)',
          span: {
            color: (t) => t.colors.primary,
          },
        },
      }}
    >
      {currentFlagLang(lang)}
      <span
        sx={{
          pl: '0.5rem',
          fontSize: '0.8rem',
          color: (t) => t.colors.gray[8],
        }}
      >
        {languagesVerbose[lang]}
      </span>
    </Link>
  )
}

export default SwitchButton
