import React from 'react'

const RightArrow = ({color, className}) => (
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 257.57 451.85"
    className={className}
  >
    <path
      d="M345.44,248.29,151.15,442.57a31.64,31.64,0,0,1-44.75-44.74L278.32,225.92,106.41,54A31.64,31.64,0,0,1,151.16,9.27L345.45,203.55a31.64,31.64,0,0,1,0,44.74Z"
      transform="translate(-97.14 0)"
      fill={color || '#ED8936'}
    />
  </svg>
)

export default RightArrow
