import styled from '@emotion/styled'

const GalleryImgWrapper = styled.div`
  overflow: visible;
  position: relative;
  cursor: pointer;
  margin-right: auto;
  margin-left: auto;
  z-index: 100;
  border-radius: 2rem;
  height: 35rem;
  margin-top: 3rem;
  flex-basis: calc(99.9% * 1 / 1.1);
  max-width: calc(99.9% * 1 / 1.1);
  width: calc(99.9% * 1 / 1.1);
  box-shadow: 0 30px 20px rgba(0, 0, 0, 0.12);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  @media (max-width: 1340px) {
    height: 30rem;
  }
  @media (max-width: 600px) {
    height: 25rem;
    margin: 3rem auto 0 auto;
    flex-basis: calc(99.9% * 1 / 1.3333);
    max-width: calc(99.9% * 1 / 1.3333);
    width: calc(99.9% * 1 / 1.3333);
  }
`

export default GalleryImgWrapper
