import React from 'react'

const MinusBtn = ({...props}) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 62 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="30" cy="30" r="30" fill="#ED8936" />
    <path
      d="M45 30L15 30"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
    />
  </svg>
)

export default MinusBtn
