exports.langPrefix = (uid = '', lang, tPath) => {
  let path = ''

  if (tPath !== undefined && typeof tPath === 'object') {
    path = `${tPath[`${lang}`]}/`
  } else if (tPath !== undefined && typeof tPath !== 'object') {
    path = `${tPath}/`
  } else {
    path = ''
  }

  switch (lang) {
    case 'de-ch':
      return `/${path}${uid}`
    case 'es-es':
      return `/es/${path}${uid}`
    case 'en-gb':
      return `/en/${path}${uid}`
    default:
      return `/${path}${uid}`
  }
}
