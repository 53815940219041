import React from 'react'

const Call = ({className, color}) => (
  <svg
    viewBox="0 0 20 20"
    className={className}
    style={{verticalAlign: 'middle', marginRight: '0.5rem'}}
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      d="M19.7391 15.8057L16.6504 12.7167C16.0352 12.104 15.0164 12.1226 14.3798 12.7594L12.8237 14.3152C12.7254 14.261 12.6236 14.2044 12.5166 14.1444C11.534 13.5999 10.189 12.8536 8.77378 11.4373C7.35433 10.018 6.60736 8.67103 6.06122 7.68774C6.00359 7.58357 5.94837 7.48313 5.89384 7.38775L6.93821 6.34494L7.45166 5.83085C8.08928 5.19307 8.10686 4.17455 7.49309 3.56004L4.4044 0.470661C3.79063 -0.142996 2.77137 -0.12438 2.13376 0.51341L1.26326 1.38891L1.28705 1.41252C0.995157 1.78497 0.751246 2.21453 0.569734 2.67776C0.402415 3.1187 0.298243 3.53947 0.250609 3.9611C-0.157231 7.34219 1.38783 10.4323 5.58092 14.6254C11.3771 20.4211 16.048 19.9833 16.2495 19.9619C16.6884 19.9095 17.1089 19.8046 17.5364 19.6386C17.9956 19.4592 18.4249 19.2157 18.7971 18.9244L18.8161 18.9413L19.698 18.0777C20.3343 17.4401 20.3526 16.4212 19.7391 15.8057Z"
      fill={`${color || '#fff'}`}
    />
  </svg>
)

export default Call
