/** @jsx jsx */
import {jsx} from 'theme-ui'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import {v4 as uuidv4} from 'uuid'

const Image = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  object-fit: cover;
  border-radius: 2rem;
  img {
    border-radius: 2rem;
  }
  > div {
    position: static !important;
  }
  > div > div {
    position: static !important;
  }
`

const GalleryImgs = (gallery, animationOff) => {
  const workImgs = []

  gallery.map((item) => {
    if (item) {
      workImgs.push(
        <div
          sx={{
            overflow: 'visible',
            position: 'relative',
            cursor: 'pointer',
            marginRight: 'auto',
            marginLeft: 'auto',
            zIndex: 100,
            borderRadius: '2rem',
            height: '35rem',
            marginTop: '3rem',
            flexBasis: 'calc(99.9% * 1 / 1.1)',
            maxWidth: 'calc(99.9% * 1 / 1.1)',
            width: 'calc(99.9% * 1 / 1.1)',
            boxShadow: '0 30px 20px rgba(0, 0, 0, 0.12)',
            transition: 'all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)',

            '@media (max-width: 1340px)': {
              height: '30rem',
            },
            '@media (max-width: 600px)': {
              height: '25rem',
              margin: '3rem auto 0 auto',
              flexBasis: 'calc(99.9% * 1 / 1.3333)',
              maxWidth: 'calc(99.9% * 1 / 1.3333)',
              width: 'calc(99.9% * 1 / 1.3333)',
            },
            '&:hover': {
              transform: animationOff ? '' : 'translateY(-12px)',
            },
          }}
        >
          <Image>
            <Img
              fluid={item.image.localFile.childImageSharp.fluid}
              style={{
                borderRadius: '2rem',
              }}
              alt={item.image.alt}
              key={uuidv4}
            />
          </Image>
        </div>
      )
    }

    return workImgs
  })
  return workImgs
}

export default GalleryImgs
