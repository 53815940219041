/** @jsx jsx */
import {useState, useEffect} from 'react'
import PropTypes from 'prop-types'

import {Link} from 'gatsby'
import {jsx, Flex} from 'theme-ui'
import MiniLogo from '../../static/logo/logo-100h-white.webp'
import locales from '../../config/i18n'
import SwitchButton from './language-switcher/button'

const Navigation = ({customNav, lang, i18nPathname}) => {
  // mobile
  const [isExpanded, toggleExpansion] = useState(false)
  // dropdown
  // const [isDrop, toggleDrop] = useState(false);
  // scroll
  const [pos, setPos] = useState(0)
  // navbar
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const temp = window.pageYOffset

      setVisible(pos > temp)
      setPos(temp)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [pos])

  const NavBarOpt = () => {
    if (customNav) {
      return (
        <img
          src={MiniLogo}
          sx={{
            display: [`none`, ``, `block`],
            height: 12,
          }}
          alt="dalia pugatsch logo"
        />
      )
    }
    if (pos > 500) {
      return (
        <img
          src={MiniLogo}
          sx={{
            display: [`none`, ``, `block`],
            height: 12,
          }}
          alt="dalia pugatsch logo"
        />
      )
    }
    return (
      <img
        src={MiniLogo}
        sx={{
          display: [`none`, ``, `block`],
          height: 12,
        }}
        alt="dalia pugatsch logo"
      />
    )
  }

  return (
    <nav
      sx={{
        backgroundColor: (t) => [
          `${t.colors.primary}`,
          ``,
          ``,
          `${pos > 500 || customNav ? `${t.colors.primary}` : 'transparent'}`,
        ],
        position: `fixed`,
        width: `100vw`,
        maxWidth: '100vw',
        padding: `0.5rem 2rem`,
        display: `${!visible && pos > 500 ? 'none' : 'flex'}`,
        flexWrap: `wrap`,
        alignItems: `center`,
        justifyContent: `space-between`,
        zIndex: 9999,
        top: 0,
        boxShadow: `${pos > 500 ? '0 20px 30px rgba(0, 0, 0, 0.1)' : ''}`,
        a: {
          fontSize: [`1.5rem`, null, null, '1rem'],
        },
      }}
    >
      <Flex>
        <Link
          sx={{
            display: `flex`,
            color: `text`,
            alignItems: `center`,
          }}
          to="/"
        >
          <NavBarOpt />
        </Link>
      </Flex>

      <div
        sx={{
          display: [`block`, `flex`, ``, `none`],
        }}
      >
        <button
          onClick={() => {
            toggleExpansion(!isExpanded)
          }}
          type="button"
          sx={{
            display: `flex`,
            alignItems: `center`,
            px: `0.75rem`,
            py: `0.5rem`,
            border: `1px solid #fff`,
            borderRadius: '0.5rem',
            textColor: `grayDark`,
            backgroundColor: (t) => `${t.colors.primary}`,
          }}
        >
          <svg
            sx={{
              fill: '#F7E4D4',
              height: `0.75rem`,
              width: `0.75rem`,
            }}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>

      <div
        sx={{
          display: [`${isExpanded ? 'block' : 'none'}`, ``, ``, `flex`],
          width: [`100vw`, ``, ``, `auto`],
          alignItems: `center`,
          paddingTop: [`2rem`, ``, ``, `0`],
          paddingBottom: [`0.5rem`, ``, ``, `0`],

          a: {
            fontWeight: `700`,
          },

          'a:nth-of-type(n):not(:last-child), div:nth-of-type(n):not(:last-child)':
            {
              mb: ['1rem', null, null, 0],
              marginRight: [`0`, ``, `1rem`],
              display: [`block`, ``, ``, `inline-block`],
              color: (t) =>
                `${customNav || pos > 600 ? '#fde3ce' : t.colors.gray[2]}`,
              // color: (t) => `${pos > 600 ? '#fde3ce' : t.colors.gray[2]}`,
              // fdd8ba
              '@media (max-width: 800px)': {
                color: '#F7E4D4',
              },
              '&:hover': {
                color: (t) => `${t.colors.gray[1]}`,
              },
            },
          'div:nth-of-type(n):not(:last-child)': {
            marginRight: [`0`, ``, `1rem`],
            display: [`block`, ``, `inline-block`],
          },
        }}
      >
        <Link
          to={locales[lang].navbar.home.path}
          sx={{
            mb: [`0.5rem`, ``, `0`],
          }}
        >
          {locales[lang].navbar.home.title}
        </Link>

        <Link
          to={locales[lang].navbar.kunstSchool.path}
          sx={{
            mb: [`0.5rem`, ``, `0`],
          }}
        >
          {locales[lang].navbar.kunstSchool.title}
        </Link>
        <Link
          to={locales[lang].navbar.daliaPugatsch.path}
          sx={{
            mb: [`0.5rem`, ``, `0`],
          }}
        >
          {locales[lang].navbar.daliaPugatsch.title}
        </Link>
        <Link
          to={locales[lang].navbar.buch.path}
          sx={{
            mb: [`0.5rem`, ``, `0`],
          }}
        >
          {locales[lang].navbar.buch.title}
        </Link>
        <Link
          to={locales[lang].navbar.blog.path}
          sx={{
            mb: [`0.5rem`, ``, `0`],
          }}
        >
          {locales[lang].navbar.blog.title}
        </Link>
        <Link
          to={locales[lang].navbar.events.path}
          sx={{
            mb: [`0.5rem`, ``, `0`],
          }}
        >
          {locales[lang].navbar.events.title}
        </Link>
        <div
          sx={{
            mb: [`0.5rem`, ``, `0`],
          }}
        >
          <SwitchButton
            lang={lang}
            i18nPathname={i18nPathname}
            isExpanded={isExpanded}
          />
        </div>
        {/* <Link
          to="/presse"
          sx={{
            mb: [`0.5rem`, ``, `0`],
          }}
        >
          Presse
        </Link> */}
        {/* DropDown */}
        {/* <div
          role="button"
          tabIndex={0}
          onKeyDown={() => toggleDrop(!isDrop)}
          onClick={() => toggleDrop(!isDrop)}
          onMouseOver={() => toggleDrop(true)}
          onFocus={() => toggleDrop(true)}
          onMouseLeave={() => toggleDrop(false)}
          onBlur={() => toggleDrop(false)}
          sx={{
            mb: [`0.5rem`, ``, `0`],
            position: `relative`,
          }}
        >
          <div
            sx={{
              cursor: `pointer`,
              display: `flex !important`,
              alignItems: `center`,
              background: `transparent`,
              border: `none`,
              fontWeight: `700`,
              // mb: `1rem !important`,
              color: (t) => [`${t.colors.gray[1]}`, ``, ``, `#F7E4D4`],
              '&:hover': {
                color: (t) => `${t.colors.gray[1]}`,
                svg: {
                  fill: (t) => t.colors.gray[1],
                },
              },

              svg: {
                display: `inline-block`,
                height: `1rem`,
                width: `1rem`,
                fill: (t) => t.colors.gray[2],
              },
            }}
          >
            Arrow Menu
            <ArrowDown />
          </div>
          <div
            sx={{
              display: isDrop ? 'inline-block' : 'none',
              position: [``, ``, ``, `absolute`],
              left: 0,
              top: 24,
              marginTop: [`-0.1rem`, ``, ``, `0`],
            }}
          >
            <div
              sx={{
                zIndex: `2 !important`,
                width: [`calc(100% + 1rem)`, ``, ``, `calc(100% + 8rem)`],
                background: (t) => [
                  `${t.colors.primary}`,
                  ``,
                  ``,
                  `${t.colors.gray[1]}`,
                ],
                boxShadow: [`none`, ``, ``, `${(t) => t.shadow.card}`],
                borderRadius: `0.5rem`,
                a: {
                  fontWeight: `600`,
                  mb: `0 !important`,
                },
                'a:nth-of-type(n)': {
                  marginRight: '0 !important',
                  display: `block !important`,
                  paddingY: [`1`, `2`],
                  paddingX: `3`,
                  color: (t) => [
                    `${t.colors.gray[1]}`,
                    ``,
                    ``,
                    `${t.colors.primary}`,
                  ],
                  '&:hover': {
                    color: (t) => `${t.colors.gray[1]} !important`,
                    background: (t) => t.colors.primary,
                  },
                },
                'a:last-of-type': {
                  borderRadius: `0 0 0.4rem 0.4rem`,
                },
                'a:first-of-type': {
                  border: `none `,
                  borderRadius: `0.4rem 0.4rem 0 0`,
                },
              }}
            >
              <Link
                to="/"
                onClick={() => {
                  toggleDrop(!isDrop);
                  toggleExpansion(!isExpanded);
                }}
              >
                Arrow 1
              </Link>
              <Link to="/">Arrow 2</Link>
              <Link to="/">Arrow 3</Link>
            </div>
          </div>
        </div> */}

        <Link
          to={locales[lang].navbar.contact.path}
          sx={{
            variant: `buttons.base`,
            display: [`table !important`, ``, `none`],
            pt: '6px',
            color: (t) => `${t.colors.primary}`,
            backgroundColor: (t) => `${t.colors.gray[1]}`,
            '&:hover': {
              color: (t) => t.colors.primary,
              transform: 'translateY(-2px) !important',
            },
          }}
        >
          {locales[lang].navbar.contact.title}
        </Link>
      </div>
    </nav>
  )
}

export default Navigation
Navigation.defaultProps = {
  customNav: false,
}

Navigation.propTypes = {
  customNav: PropTypes.bool,
}
