/** @jsx jsx */
import {jsx} from 'theme-ui'
import {Link} from 'gatsby'
import anchorLink from './anchor-link'

const GatsbyLink = ({link, children, anchor, ...rest}) => {
  let anchorGatsbyLink = ''

  if (typeof link === 'string') {
    anchorGatsbyLink = anchor ? `${link}${anchorLink(anchor)}` : link
  } else {
    anchorGatsbyLink = anchor ? `${link.uid}${anchorLink(anchor)}` : link.uid
  }

  return (
    <Link to={anchorGatsbyLink} {...rest}>
      {children}
    </Link>
  )
}

export default GatsbyLink
