/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
import {useState} from 'react'
import Lightbox from 'react-image-lightbox'
// import PropTypes from 'prop-types';
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import {jsx, Styled} from 'theme-ui'

const ContentSection = ({title, content, lgImage, isReversed}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  const images = []
  images.push(lgImage.it_image.url)

  const HandleClick = (key) => {
    setIsOpen(true)
    setPhotoIndex(key)
  }

  return (
    // Wrapper
    <div
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginBottom: '4rem',
        maxWidth: '1200px',
        margin: '0 auto',
      }}
    >
      <div
        sx={{
          order: ['0', '', isReversed ? '1' : ''],
          flexBasis: 'calc(99.9% * 2 / 5 - 1rem)',
          maxWidth: 'calc(99.9% * 2 / 5 - 1rem)',
          width: 'calc(99.9% * 2 / 5 - 1rem)',
          h1: {
            '-webkit-hyphens': 'auto',
            '-ms-hyphens': 'auto',
            hyphens: 'auto',
          },
          '@media (max-width: 800px)': {
            flexBasis: '100%',
            maxWidth: '100%',
            width: '100%',
          },
        }}
      >
        <Styled.h1>{title}</Styled.h1>
        <Styled.p>{content}</Styled.p>
      </div>

      {/* Image */}
      <div
        onClick={() => HandleClick(0)}
        onKeyDown={() => HandleClick(0)}
        sx={{
          overflow: 'hidden',
          order: ['0', '', isReversed ? '0' : ''],
          position: 'relative',
          mt: ['0.5rem', '', '', '0'],
          cursor: 'zoom-in',
          transition: 'all 0.4s ease-in-out',
          borderRadius: (t) => t.borderRadius.default,

          div: {
            transition: 'transform 0.4s',
            maxHeight: '32rem',
            borderRadius: (t) => t.borderRadius.default,
          },
          img: {
            borderRadius: (t) => t.borderRadius.default,
            margin: '0 auto',
            width: '100%',
            maxHeight: '32rem',
          },
          '&:hover': {
            boxShadow: '0 20px 30px rgba(0, 0, 0, 0.08)',
            div: {
              borderRadius: '1rem',
              transform: 'scale(1.05)',
            },
          },
          flexBasis: 'calc(99.9% * 3 / 5 - 3rem)',
          maxWidth: 'calc(99.9% * 3 / 5 - 3rem)',
          width: 'calc(99.9% * 3 / 5 - 3rem)',
          maxHeight: '32rem',
          '@media (max-width: 800px)': {
            flexBasis: '100%',
            maxWidth: '100%',
            width: '100%',
          },
        }}
      >
        <Img fluid={lgImage.it_image.localFile.childImageSharp.fluid} />
      </div>
      {!!isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
    </div>
  )
}

// const ContentSection = ({ title, content, img }) => {

ContentSection.defaultProps = {
  title: 'Title',
  content: 'Content',
}

ContentSection.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
}

export default ContentSection
