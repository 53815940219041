/** @jsx jsx */
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import {jsx, Flex, Box, Container, Styled} from 'theme-ui'
// icons
import {Paperplane, Facebook, Instagram, RightArrow} from '../images/icons'
// separators

const Footer = ({className, data}) => (
  <div
    className={className}
    sx={{
      width: `100%`,
      mb: 0,
      lineHeight: 0,
      display: `inline-block`,
      backgroundColor: (t) => t.colors.primary,
    }}
  >
    <Container sx={{py: 4, margin: `0 auto`, px: `0.5rem`, maxWidth: '1200px'}}>
      <Flex
        sx={{
          justifyContent: [``, ``, ``, `space-between`],
          alignItems: [`center`, ``, ``, `flex-start`],
          alignContent: `flex-start !important`,
          marginBottom: `3rem`,
          flexDirection: [`column`, ``, ``, `row`],
        }}
      >
        <Link to="/">
          <Img
            fluid={data.logo.localFile.childImageSharp.fluid}
            style={{
              height: 'auto',
              width: '12rem',
            }}
            sx={{
              mb: [`1.5rem`, ``, ``, `0`],
            }}
            alt="dalia pugatsch logo"
          />
        </Link>
        {/* Second column */}
        <Box
          sx={{
            textAlign: `left !important`,
            alignContent: `flex-start !important`,
            mb: [`1.5rem`, ``, ``, `0`],
          }}
        >
          <h1
            sx={{
              fontWeight: `semibold`,
              fontSize: `1.6rem`,
              color: (t) => t.colors.gray[1],
              pb: 2,
            }}
          >
            {data.c1_title}
          </h1>

          {data.links.map((link) => (
            <Link
              to={`/${link.link.uid}`}
              sx={{
                h5: {
                  transition: 'all 0.2s',
                },
                'h5:hover': {
                  transition: 'all 0.2s ease-in-out',
                  pl: 2,
                  color: (t) => t.colors.blue[3],
                  svg: {
                    path: {
                      fill: (t) => `${t.colors.blue[3]} !important`,
                    },
                  },
                },
              }}
            >
              <p
                sx={{
                  color: (t) => t.colors.gray[1],
                  fontSize: `1.5rem`,
                  fontWeight: 500,
                  paddingLeft: 1,
                }}
              >
                <RightArrow
                  sx={{
                    height: `1rem`,
                    path: {fill: (t) => t.colors.gray[1]},
                    mr: 2,
                    pl: 2,
                  }}
                />
                {link.link_name}
              </p>
            </Link>
          ))}
        </Box>
        <Box sx={{display: `flex`, flexDirection: `column`}}>
          <h1
            sx={{
              fontWeight: `semibold`,
              fontSize: `1.6rem`,
              color: (t) => t.colors.gray[1],
              pb: 2,
            }}
          >
            {data.titel}
          </h1>

          <a href="mailto:dalia@pugatsch.ch?subject=Generelle Informationen">
            <button
              type="button"
              sx={{
                variant: 'buttons.primary',
                backgroundColor: (t) => t.colors.gray[1],
                fontSize: '1rem',
                color: 'primary',
                '&:hover': {
                  boxShadow: 'button.hover',
                  transform: 'translateY(-4px)',
                },
                boxShadow: (t) => `${t.shadows.button.dblack} !important`,
                svg: {
                  height: '1rem',
                },
              }}
            >
              <Paperplane color="#ED8936" /> {data.btn_name}
            </button>
          </a>
        </Box>
      </Flex>
      <Flex
        sx={{
          flexDirection: `column`,
          alignItems: `center`,
        }}
      >
        <Box
          sx={{
            pt: [4, '', 2],
            margin: `0 auto`,
            svg: {
              height: `2rem`,
              width: `2rem`,
              color: `#fff`,
              path: {
                fill: `#fff`,
              },
            },
          }}
        >
          <a
            href={data.instagram_link.url}
            sx={{
              mr: 4,
            }}
          >
            <Instagram />
          </a>
          <a href={data.facebook_link.url}>
            <Facebook />
          </a>
        </Box>

        <Flex
          sx={{
            pt: '3rem',
            fontSize: [2, 2],
            flexDirection: [`column`, ``, ``, `row`],
            margin: `0 auto !important`,
            textAlign: `center`,
          }}
        >
          <Link
            to="/impressum"
            sx={{
              height: `2rem !important`,
              color: '#fde3ce',
              '&:hover': {
                color: '#fff !important',
              },
            }}
          >
            Impressum
          </Link>
          <span
            sx={{
              display: ['none', '', 'inline'],
              px: 2,
              color: (t) => t.colors.white,
            }}
          >
            -
          </span>
          <Link
            to="/datenschutzbestimmungen"
            sx={{
              color: '#fde3ce',
              height: `100% !important`,
              width: `100% !important`,
              overflowWrap: `break-word`,
              '&:hover': {
                color: '#fff',
              },
            }}
          >
            Datenschutzbestimmungen
          </Link>
        </Flex>
        <Styled.p
          sx={{
            pt: [4, '', 2],
            fontSize: `1rem !important`,
            color: '#fde3ce',
            margin: `0 auto !important`,
            textAlign: `center`,
          }}
        >
          {data.copyrightzeichen}
        </Styled.p>
      </Flex>
    </Container>
  </div>
)

Footer.defaultProps = {
  className: '',
}

Footer.propTypes = {
  className: PropTypes.string,
}

export default Footer
