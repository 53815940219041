/** @jsx jsx */
import {jsx, Styled, Flex, Box} from 'theme-ui'
import PropTypes from 'prop-types'

const Hero = ({title, subtitle}) => (
  <div
    sx={{
      position: `relative`,
      minHeight: `90vh`,
      display: `flex`,
      alignItems: `center`,
      background: (t) => t.gradients.primary,
    }}
  >
    <div
      sx={{
        paddingBottom: ['0', '', '', '5rem'],
        maxWidth: `1400px`,
        margin: `0 auto`,
      }}
    >
      <Flex
        sx={{
          alignItems: `center`,
          px: 4,
          flexDirection: [`column`, ``, ``, `row`],
          justifyContent: `space-around`,
          margin: `0 auto`,
        }}
      >
        <Box
          sx={{
            order: [`2`, ``, ``, `1`],
            maxWidth: `1200px`,
            paddingRight: [`0`, ``, ``, `5rem`],
          }}
        >
          <Styled.h1
            sx={{
              textShadow: [(t) => `${t.shadows.text.big}`],
            }}
          >
            {title}
          </Styled.h1>
          <Styled.h2
            sx={{
              textShadow: [(t) => `${t.shadows.text.big}`],
            }}
          >
            {subtitle}
          </Styled.h2>
        </Box>
      </Flex>
    </div>
  </div>
)

export default Hero

Hero.defaultProps = {
  title: 'Title',
  subtitle: 'Subtitle',
}

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}
