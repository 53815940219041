const locales = {
  'de-ch': {
    blogEntry: 'Blogeinträge',
    navbar: {
      home: {
        title: 'Home',
        path: '/',
      },
      kunstSchool: {
        title: 'Kunstschule',
        path: '/kunstschule',
      },
      daliaPugatsch: {
        title: 'Dalia Pugatsch',
        path: '/dalia-pugatsch',
      },
      buch: {
        title: 'Buch',
        path: '/kunst-revolution',
      },
      blog: {
        title: 'Blog',
        path: '/blog',
      },
      events: {
        title: 'Veranstaltungen',
        path: '/veranstaltungen',
      },
      contact: {
        title: 'Kontakt',
        path: '/kontakt',
      },
      langSwitcher: {
        title: 'Sprache ändern',
      },
    },
  },

  'es-es': {
    blogEntry: 'Últimos Posts',
    navbar: {
      home: {
        title: 'Inicio',
        path: '/es',
      },
      kunstSchool: {
        title: 'Escuela de Arte',
        path: '/es/escuela-de-arte',
      },
      daliaPugatsch: {
        title: 'Dalia Pugatsch',
        path: '/es/dalia-pugatsch',
      },
      buch: {
        title: 'Libro',
        path: '/es/revolucion-del-arte',
      },
      blog: {
        title: 'Blog',
        path: '/es/blog',
      },
      events: {
        title: 'Eventos',
        path: '/es/eventos',
      },
      contact: {
        title: 'Contactar',
        path: '/es/contactar',
      },
      langSwitcher: {
        title: 'Cambiar idioma',
      },
    },
  },

  'en-gb': {
    blogEntry: 'Latest posts',
    navbar: {
      home: {
        title: 'Home',
        path: '/en',
      },
      kunstSchool: {
        title: 'Art School',
        path: '/en/art-school',
      },
      daliaPugatsch: {
        title: 'Dalia Pugatsch',
        path: '/en/dalia-pugatsch',
      },
      buch: {
        title: 'Book',
        path: '/en/kunstrevolution',
      },
      blog: {
        title: 'Blog',
        path: '/en/blog',
      },
      events: {
        title: 'Events',
        path: '/en/events',
      },
      contact: {
        title: 'Contact',
        path: '/en/contact',
      },
      langSwitcher: {
        title: 'Change language',
      },
    },
  },
}

export default locales
