/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/** @jsx jsx */
import {useState} from 'react'
import {jsx} from 'theme-ui'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Carousel, {Modal, ModalGateway} from 'react-images'
import Gallery from 'react-photo-gallery'

const GatsbyImage = ({index, onClick, photo, margin}) => (
  <div
    sx={{
      overflow: 'hidden',
      boxShadow: '0 9px 20px rgba(0, 0, 0, 0.12)',
      transition: 'all 0.2s ease-in-out',
      cursor: 'zoom-in',
      borderRadius: '1rem',
      m: margin,
      height: photo.height,
      width: photo.width,
      img: {
        borderRadius: '1rem',
      },
      div: {
        transition: 'transform 0.4s',
      },
      '&:hover': {
        boxShadow: '0 5px 30px rgba(0, 0, 0, 0.12)',
        div: {
          borderRadius: '1rem',
          transform: 'scale(1.05)',
        },
      },
    }}
    style={{margin, height: photo.height, width: photo.width}}
    onClick={(e) => onClick(e, {index, photo})}
    key={photo.key}
  >
    <Img fluid={photo.fluid} />
  </div>
)

const styleFn = (styleObj) => ({...styleObj, zIndex: 100})

const getImages = (imageArray) =>
  [...imageArray].map(
    ({
      sec2_gallery_image: {
        localFile: {
          childImageSharp: {fluid, original},
        },
      },
    }) => ({
      height: original.height,
      width: original.width,
      src: fluid.src,
      srcSet: fluid.srcSet,
      fluid,
      key: fluid.originalName,
    })
  )
function PhotoGallery({photos}) {
  const [isOpen, setOpen] = useState(false)
  const [current, setCurrent] = useState(0)
  const images = getImages(photos)

  const imageClick = (e, obj) => {
    setCurrent(obj.index)
    setOpen(true)
  }

  return (
    <div
      sx={{
        maxWidth: '1200px',
        margin: '0 auto',
      }}
    >
      <Gallery
        photos={images}
        renderImage={GatsbyImage}
        margin={5}
        onClick={imageClick}
      />
      <ModalGateway>
        {isOpen ? (
          <Modal
            onClose={() => {
              setCurrent(0)
              setOpen(false)
            }}
            styles={{blanket: styleFn, positioner: styleFn}}
          >
            <Carousel views={images} currentIndex={current} />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}

PhotoGallery.propTypes = {
  // eslint-disable-next-line react/require-default-props
  photos: PropTypes.array.isRequired,
}

export default PhotoGallery
